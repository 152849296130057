import { mapGetters, mapActions } from 'vuex'
import { programs } from '../../../constant'
import { scrollToTheTarget } from '@/utils'
export default {
  name: 'course',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel')
  },
  data() {
    return {
      prasyaratClass: false,
      paginationSize: 10,
      contentVisible: false,
      carouselTestimonyOptions: {
        autoplay: true,
        arrows: false,
        perPage: 1,
        speed: 1000,
        pagination: true,
        interval: 5000,
        gap: '2rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      list: programs
    }
  },
  created() {
    this.getTestimonies()
  },
  computed: {
    ...mapGetters('testimony', ['testimonies'])
  },
  mounted() {
    if (this.$route.hash) {
      scrollToTheTarget(this.$route.hash, -100)
    } else {
      scrollToTheTarget('#program-page', -100)
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  watch: {
    '$route.hash': function (val) {
      if (this.$route.hash) {
        scrollToTheTarget(val, -100)
      }
    }
  },
  methods: {
    ...mapActions('testimony', ['getAllTestimonies']),
    getTestimonies: function () {
      this.getAllTestimonies().then(() => {
        this.contentVisible = true
      })
    },
    goToDetail: function (item) {
      if (item.category === 'Short Course') {
        this.$router.push(`${item.onClick}`)
        return
      }
      this.$router.push(`/class/bootcamp/${item.onClick}`)
    }
  }
}
